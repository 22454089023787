export function convertYearsToYearsAndMonths(duration) {
    const years = Math.floor(duration);
    const months = Math.round((duration - years) * 12);
    return [years, months];
}

export const PLACEHOLDER_FORMAT = 'PLACEHOLDER';

export const MOMENT_FORMAT = 'MOMENT';

export const getDateFormat = (type) => {
    const formatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    const parts = formatter.formatToParts(new Date());

    const format = parts
        .map((part) => {
        switch (part.type) {
            case 'day':
            return type === PLACEHOLDER_FORMAT ? '01' : 
                type === MOMENT_FORMAT ? 'DD' : '99';
            case 'month':
            return type === PLACEHOLDER_FORMAT ? '01' : 
                type === MOMENT_FORMAT ? 'MM' : '99'
            case 'year':
            return type === PLACEHOLDER_FORMAT ? '2000' :
                type === MOMENT_FORMAT ? 'YYYY' : '9999';
            default:
            return part.value;
        }
        })
        .join('');
    return format;
}
