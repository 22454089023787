import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const fetchUserData = createAsyncThunk(
    'user/load',
    async () => {
        const response = await api.get('/user');
        return response.data;
    }
);

const initialState = {
  loading: false,
  userData: {},
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clear: () => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            if (action.payload.health_results) {
                action.payload.health_results = action.payload.health_results.map((r) => {
                    const date = new Date(r.date);
                    return {
                        ...r,
                        date,
                        registered_at: action.payload.kits.find((k) => k.id === r.kit_id)?.registered_at,
                        chronological_age: action.payload.kits.find((k) => k.id === r.kit_id)?.chronological_age,
                    }
                })
                
                action.payload.health_results.sort((a, b) => 
                    new Date(b.registered_at).getTime() - new Date(a.registered_at).getTime())

                
                
            }
            state.loading = false;
            state.userData = action.payload;
        });
        builder.addCase(fetchUserData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        })
    }
})

export const { clear } = userSlice.actions;

export default userSlice.reducer;
  