import moment from 'moment'
import { 
    AGE_MAPPING, 
    CHAR_MAPPING, 
    DECRYPT_AGE_MAPPING, 
    REVERSE_CHAR_MAPPING 
} from "../constants/age-mapping"
import { GENDER_MAPPING, REVERSE_POSSESSIVE_PRONOUM_MAPPING } from "../constants/gender-mapping";



export const getToken = (payload) => {
    let {
        biological_age,
        chronological_age,
        first_name,
        gender
    } = payload;

    biological_age = biological_age.toFixed(1);
    chronological_age = chronological_age.toFixed(1);
    
    const firstNameToken = first_name.split("").map((letter) => {
        return CHAR_MAPPING[letter] || letter
    }).join('');
    const biologicalAgeToken = AGE_MAPPING[biological_age];
    const chronologicalAgeToken = AGE_MAPPING[chronological_age];
    const genderToken = GENDER_MAPPING[gender];
    const token = `${biologicalAgeToken}${chronologicalAgeToken}${genderToken}${firstNameToken}`;
    return token;
}   


export const decryptToken = (token) => {
    const biological_age = DECRYPT_AGE_MAPPING[token.substring(0, 4)];
    const chronological_age = DECRYPT_AGE_MAPPING[token.substring(4, 8)];
    const first_name = token.substring(9).split("").map((char) =>  REVERSE_CHAR_MAPPING[char] || char).join("");
    const possessive_pronoun = REVERSE_POSSESSIVE_PRONOUM_MAPPING[token[8]] || 'their'
    return {
        biological_age,
        chronological_age,
        first_name,
        possessive_pronoun,
    }
}   

export const getAgeDifference = (biological_age, chronological_age) => {
    const difference = Math.abs(biological_age - chronological_age).toFixed(1);
    return difference    
}

export const calculateAge = (dateString, dataFormat) => moment().diff(moment(dateString, dataFormat), 'years')

