import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Column, Row } from './defaults';

const wrapperTransform = (translation) => keyframes`
    0% {
        opacity: 0;
        transform: translateY(${translation}px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);


    }
`;


const animationMock = (translation) => keyframes`
 0% { 
    transform: translateY(0px);
    padding-top: 150px;
    padding-bottom: 150px;
}
 100% { 
    transform: translateY(-${translation}px);
    padding-top: 0px;
    padding-bottom: 0px;
 }
`;

const animationMockReverse = (translation) => keyframes`
 0% { 
    transform: translateY(-${translation + 0.5 * translation}px);
    padding-top: 150px;
    padding-bottom: 150px;
}
 100% { 
    transform: translateY(0px);
    padding-top: 0px;
    padding-bottom: 0px;
 }
`;

const FinalAgeWrapper = styled(Column)`
    position: relative;
    align-items: center;
    justify-content: center;
    z-index: 3;
    animation:  ${props => wrapperTransform(50)} 0.8s cubic-bezier(.15,.84,.54,.97);
    animation-fill-mode: forwards;

    @media (max-width: 1100px) {
        transform: scale(0.8) !important;
    }
`;


const Integer = styled.div`
    position: relative;
    font-size: ${props => props.fontSize}px;
    line-height: ${props => props.height + 10}px;
    height: ${props => props.height}px;
    color: white;
    font-family: 'Satoshi-Regular';
    transform: ${props => props.translation ? `transform: translateY(-${props.translation + 0.5 * props.translation}px)` : 'initial'};

    @media (max-width: 768px) {
        font-size: ${props => props.fontSize - 30}px;
        line-height: ${props => props.height - 10}px;
        height: ${props => props.height}px;
    }
`;

const Roulete = styled(Column)`
    position: relative;
    height: ${props => props.height + 20}px;
    align-items: center;
    overflow: hidden;
    width: ${props => props.width}px;
`;

const Wrapper = styled(Column)`
    position: absolute;
    animation:  ${props => props.reverse ?
        animationMockReverse(props.translation):
        animationMock(props.translation)} 2.2s cubic-bezier(.15,.84,.54,.97);
    animation-fill-mode: forwards;
`;

const RouleteWrapper = styled.div`
    
`;

const NumberRoulete = ({ 
    prefix = '', 
    value, 
    numberStyle = {},
    reverse, 
    width = 100,
    numberToRender = 4, 
    slideTranslate }) => {

    const mockValues = []
    const height = 160;
    const fontSize = 180;
    
    for (let i = 0; i !== numberToRender ; i++ ) {
        if (value - i < 0) {
            value = 9 + i
        }
        mockValues.push(value - i);
    };

    if (!reverse) mockValues.reverse()

    return <RouleteWrapper slideTranslate={slideTranslate}>
    <Roulete height={height} width={width}>
        <Wrapper
        height={height} width={width}
        reverse={reverse}
        translation={height * (mockValues.length - 1)}>
        {mockValues.map((v) => <Integer style={numberStyle} translation={height * (mockValues.length - 1)} height={height} fontSize={fontSize} width={width}>{prefix}{v}</Integer>)}
        </Wrapper>
    </Roulete>
    </RouleteWrapper>
}

const Decimal = styled.div`
    transform: scale(0.6);
    margin-top: -30px;
    margin-left: -35px;
`;

const Years = styled.div`
    color: white;
    font-family: 'Satoshi-Regular';
    text-transform: lowercase;
    font-size: 70px;
    margin-top: -75px;
    margin-left: 10px;

    @media (max-width: 768px) {
        font-size: 50px;
    }
`;

export const FinalAge = ({ age, wrapperStyle = {} }) => {
    age = Number(age).toFixed(1).toString().split('.');
    const [ageInteger, ageDecimal] = age;

    return <FinalAgeWrapper wrapperStyle={wrapperStyle}>
        <Row style={{ margin: 40}}> 
            <Row style={{ gap: 10 }}>
            {
                ageInteger.split('').map((a, i) => (
                    <NumberRoulete
                        key={a}
                        width={110}
                        numberStyle={i === 0 ? { alignSelf: 'flex-end'} : { alignSelf: 'flex-start'}}
                        reverse={i % 2 === 0}
                        value={parseInt(a)}
                        numberToRender={(i + 1) * 4}
                    />
                ))
            }
            </Row>
            <Column>
                <Decimal>
                    <NumberRoulete reverse width={150} value={ageDecimal || 0} prefix={'.'} numberToRender={10} />
                </Decimal>
                <Years>
                    Years
                </Years>
            </Column>
        </Row>
    </FinalAgeWrapper>
}

