import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Dropdown } from '../defaults';
import { moveToNextFocusable } from '../../utils/input';


const FieldQuestion = styled.span`
  color: #051F73;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const FieldQuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`

const FieldInputWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FieldLegend = styled.span`
  text-align: center;
  outline: none;
  border: none;
  color: ${props => !!props.value ? '#4D56F2' : 'rgba(5, 31, 115, 0.33)'};
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const FieldInput = styled.input`
  width: ${props => props?.value && props?.value.length > 1 ? (props?.value?.length) + 'ch' : (props.placeholder?.length) + 'ch' };
  padding: 0;
  text-align: center;
  outline: none;
  border: none;
  color: ${props => !!props.value ? '#4D56F2' : 'rgba(5, 31, 115, 0.33)'};
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  
  /* Optional: Remove number input spinner in all browsers */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: rgba(5, 31, 115, 0.33);
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const FieldInputs = styled.div`
  display: flex;
  flex-wrap: 1;
  gap: 6px;
`

const FieldQuestionWithDropdown = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`

const CustomDropdown = styled(Dropdown)`
  z-index: 99999;
`

const FieldInputsWithDropbox = ({
  field,
  fieldAnswers,
  onUpdate,
  submitAction
}) => {
  const [optionSelected, setOptionSelected] = useState(field.options[0].label)
  const [answer, setAnswer] = useState({});
  const wrapperRef = useRef();

  useEffect(() => {
    if (isValid()) {
      onUpdate(true, answer);
    } else {
      onUpdate(false, answer);
    }
  }, [answer]);

  useEffect(() => {
    resetOldAnswer()
  }, [optionSelected])

  useEffect(() => {
    field.options.map(fieldOption => {
      fieldOption.inputs.map(input => {
        const defaultValue = fieldAnswers[input.name]
        if (defaultValue) {
          formatValue(defaultValue, input)
          setOptionSelected(fieldOption.label)
        }
      })
    })
  }, [])

  const resetOldAnswer =() => {
    field.options.map(option => {
      option.inputs.map(input => {
        if (option.label !== optionSelected) {
          setAnswer(previous => ({
            ...previous,
            [input.name]: undefined
          }))
        }
      })
    })
  }

  const getCurrentInputs = () => field.options.find(opt => opt.label == optionSelected).inputs

  const isValid = () => {
    const inputs = getCurrentInputs()
    const hasInvalidAnswers = inputs.some(inputAnswer => (!answer[inputAnswer.name] || answer[inputAnswer.name] === ''))
    return !(hasInvalidAnswers || !Object.keys(answer).length)
  }

  const formatValue = (value, input) => {
    setAnswer(previous => ({
      ...previous,
      [input.name]: value
    }))
  }

  const handleInputChange = (value, input) => {
    if (input.minValue !== undefined && value < input.minValue) {
      return
    }
    if (input.maxValue !== undefined && value > input.maxValue) {
      return
    }
    formatValue(value, input)
  };

  const action = () => {
    if (!isValid()) return;
    submitAction()
  }
  
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      moveToNextFocusable(wrapperRef.current, e, action, { selector: 'input' })
    }
  }

  return <FieldQuestionWithDropdown>
      <FieldQuestionWrapper>
        <FieldQuestion>
          {field.question}
        </FieldQuestion>
        <FieldInputs ref={wrapperRef}>
          {getCurrentInputs().map((input, idx) => {
            const inputValue = answer[input.name]
            return (
              <FieldInputWrapper key={input.legend}>
                <FieldInput
                  value={inputValue || ""}
                  placeholder={input.placeholder}
                  onChange={(e) => handleInputChange(e.target.value, input)}
                  inputMode={input.inputMode ? input.inputMode : 'text'}
                  type={input.type ? input.type : 'text'}
                  autoFocus={idx === 0}
                  onKeyDown={onKeyDown}
                  enterKeyHint='Next'
                  
                />
                <FieldLegend value={inputValue}>{input.legend}</FieldLegend>
              </FieldInputWrapper>
            )
          })}
        </FieldInputs>
      </FieldQuestionWrapper>
      <CustomDropdown
        values={[{ label: optionSelected }]}
        onChange={(items) => items.length && setOptionSelected(items[0].label)}
        options={field.options.map(option => option.label)}
        searchable={false}
        backspaceDelete={false}
      />
    </FieldQuestionWithDropdown>
}

export default FieldInputsWithDropbox