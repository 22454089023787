import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';

const Wrapper = styled(Row)`
    height: 4px;
    width: ${props => props.width};
    background: #F1F3F7;
    border-radius: 4px;
    overflow: hidden;
`;

const Fill = styled.div`
    height: 100%;
    flex: ${props => props.flex};
    background: ${props => props.background};
    transition: flex 0.5s ease-in-out;
`;

const Empty = styled.div`
    height: 100%;
    flex: ${props => props.flex};
    background: transparent;
`;

export const ProgressBar = ({ percent = 50, width = '100px', background = '#F9BF29' }) => {

    return <Wrapper width={width}>
        <Fill flex={percent} background={background} />
        <Empty flex={100-percent} />
    </Wrapper>


}

