import React from "react";
import styled from "styled-components";

const Mask = styled.div`
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.8);
    z-index: 10000;
`;


export const Modal = ({ children, onClickOutside, className }) => {

    const onClickMask = (e) => {
        e.stopPropagation();
        if (onClickOutside) onClickOutside();
    }

    const onClickContent = (e) => {
        e.stopPropagation();
    }

    return <Mask className={className} onClick={onClickMask}>
        <div onClick={onClickContent}>
            {children}
        </div>
    </Mask>

}