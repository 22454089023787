import moment from "moment"
import { convertYearsToYearsAndMonths } from "./date"


export const dateInputFormatter = (dateString) => {

    return moment(new Date(dateString)).format('YYYY-MM-DD')

}

export const dropdownInputFormatter = (value, options ) => {
    return options.find((op) => op?.value === value)
}

export const capitalize = (value) => {
    if (!value) {
        return value;
    }
    return `${value[0].toLocaleUpperCase()}${value.substring(1).toLocaleLowerCase()}`
}

export const getDifferenceString = (referenceAge, chronologicalAge) => {

    const difference = convertYearsToYearsAndMonths(Math.abs(referenceAge - chronologicalAge));
    const [years, months] = difference
    const younger = referenceAge < chronologicalAge;

    const yearsString = years === 1 ? `${years} year` : `${years} years`;
    const monthsString = !months ? '' : months === 1 ? `and ${months} month` : `and ${months} months`;
    return `${yearsString} ${monthsString} ${younger ? 'younger' : 'older'} than my chronological age.`;
}
