import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import InputMask from "react-input-mask";
import moment from 'moment'
import { getDateFormat, MOMENT_FORMAT } from '../../utils/date';
import { DatePicker } from '../date-picker';

const FieldQuestionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`

const FieldInputWrapper = styled(InputMask)`
  text-align: justify;
  padding: 0;
  outline: none;
  border: none;
  margin-left: 10px;
  color: ${props => props.inputError ? '#fa8686' : !!props.value ? '#4D56F2' : 'rgba(5, 31, 115, 0.33)'};
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 200px;

  @media (max-width: 768px) {
    font-size: 24px;
    width: 150px;
  }
`

const FieldDate = ({ field, fieldAnswers, onUpdate, submitAction }) => {
  const [inputValue, setInputValue] = useState();
  const [inputError, setInputError] = useState();
  const [isDateCompleted, setIsDateCompleted] = useState();
  const [calendarOpen, setCalendarOpen] = useState(false);

  useEffect(() => {
    if (isDateCompleted && !inputError) {
      onUpdate(true, { [field.name]: inputValue });
    } else {
      onUpdate(false, null);
    }
  }, [inputError, inputValue, isDateCompleted]);

  useEffect(() => {
    if (fieldAnswers[field.name]) {
      setInputValue(fieldAnswers[field.name])
      setIsDateCompleted(true)
    }
  }, []);

  const handleChange = (e) => {
    const rawValue = e.target.value;
    setInputValue(rawValue);
    setInputError(undefined);
    setIsDateCompleted(false);
    if (rawValue.length === field.dateFormat.length) {
      const date = moment(rawValue, getDateFormat(MOMENT_FORMAT), true)
      if (!date.isValid()) {
        setInputError('Invalid date')
        return
      }
      setInputValue(date.format(getDateFormat(MOMENT_FORMAT)))
      setIsDateCompleted(true)
    }

  };

  const onPickDate = (value) => {
    const newValue = moment(value).format(getDateFormat(MOMENT_FORMAT));
    setInputValue(newValue)
    setCalendarOpen(false);
    handleChange({ target: { value: newValue } })
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!isDateCompleted) return;
      submitAction()
    }
  }
  
  return (
    <FieldQuestionWrapper>
        <FieldInputWrapper
          value={inputValue}
          inputValue={inputValue}
          mask={field.dateFormat}
          onChange={handleChange}
          inputError={inputError}
          inputMode={field.inputMode}
          placeholder={field.placeholder}
          defaultValue={!!inputValue && moment(inputValue).format('YYYY/MM/DD')}
          onKeyDown={onKeyDown}
        /> 
      <DatePicker 
      onPickDate={onPickDate} 
      calendarOpen={calendarOpen} 
      setCalendarOpen={setCalendarOpen} />

    </FieldQuestionWrapper>
  );
};

export default FieldDate;
