import Calendar from "react-calendar";
import styled from 'styled-components';
import 'react-calendar/dist/Calendar.css';
import datePickerIcon from '../images/date-picker.svg';
import { Modal } from './modal';


const DatePickerIcon = styled.img`
  height: 30px;
  width: 30px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`;


const DatePickerWrapper = styled.div`
  .react-calendar__tile--now {
    background: #4D56F2 !important;
    color: white !important;
  }

  .react-calendar__tile {
    font-family: 'Satoshi-Medium';
    color: black;
    border-radius: 0px;
    
  }

  .react-calendar {
    padding: 20px;
    border-radius: 20px;
  }

  .react-calendar__month-view__weekdays__weekday  {
    text-decoration: none !important;
    font-family: 'Satoshi-Medium';
    letter-spacing: 1px !important;
  }

  .react-calendar__navigation__label__labelText--from {
    font-family: 'Satoshi-Bold';
  
  }

`;


export const DatePicker = ({
    calendarOpen,
    setCalendarOpen,
    onPickDate
}) => {
    return <>
        <DatePickerIcon src={datePickerIcon} onClick={() => setCalendarOpen(true)} />
        {calendarOpen && <Modal onClickOutside={() => setCalendarOpen(false)}>
        <DatePickerWrapper>
            <Calendar onChange={onPickDate} maxDate={new Date()} />
        </DatePickerWrapper>
        </Modal>}
    </>
}
