import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import styled from "styled-components";
import { AcceptTerms } from "../../components/accept-terms";
import { useState } from "react";
import arrowWhite from "../../images/arrow-white.svg";
import logo from "../../images/logo-white.svg";
import loadingIcon from '../../images/rings-big.svg';
import api from "../../utils/api";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


const phoneMask = "999 999-9999";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const ConfirmButton = (props) => {

    const icon = props.loading ? loadingIcon : arrowWhite;

    return <button className={`
    disabled:opacity-50 disabled:cursor-not-allowed
    text-16 font-satoshi-bold items-center self-center
    bg-purple flex gap-3 text-white justify-center
    rounded-full h-[50px] px-10 mt-4 mb-2`} {...props}>
        Create Account
        <img src={icon} className={props.loading ? 'h-[20px]' : 'h-[10px]'} alt='' />
    </button>

}

const Header = () => {

    return <div className="bg-white border-b-2 border-b-lightpurple flex flex-col justify-center h-[90px] px-[24px]">
        <img src={logo} alt='TrumeLabs' className="h-[60px] self-start" />
    </div>


}   

const Input = ({ 
    className, 
    type, 
    label, 
    errors,
    register,
    watch,
    id,
    formProps = { required: true },
    ...props 
}) => {

    const mask = type === 'phone' ? phoneMask : null;

    if (formProps.required) formProps.required = 'This field is required';
    if (formProps.enforcePattern) formProps.pattern = {
        value: formProps.enforcePattern,
        message: `Invalid ${label}`
    }

    const sharedProps = {
        className: `
            h-[56px] w-full box-border px-4 bg-lightgray border-lightgray 
            border-[1px] outline-none rounded-md text-gray-700
        `, 
        ...register(id, formProps)
    }

    const errorMessage = errors[id]?.message;

    return <label style={{ gridArea: id }} className={`flex gap-1 flex-col ${className}`}>
        <span className="text-coolgray font-satoshi-medium text-16">{label}</span>
        {mask ? <InputMask mask={mask} type={type} maskChar={null} {...props} {...sharedProps} /> :
        <input {...props} {...sharedProps} type={type}/>}
        {errorMessage && <span className="text-red-500 text-sm">{errorMessage}</span>}
    </label>

}

const Form = styled.div`
    display: grid;
    column-gap: 20px;
    row-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'first_name last_name' 'phone phone' 'email email' 'password password' 'confirmPassword confirmPassword';

    @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 'first_name' 'last_name' 'phone' 'email' 'password' 'confirmPassword';
    }

    * {
        box-sizing: border-box;
    }
`;

const Wrapper = styled.div`
    background: linear-gradient(180deg, rgb(243, 244, 255) 0%, rgba(243, 244, 255, 0.2) 10%, #FFFFFF 100%);
`;


export const SignUp = () => {

    const [loading, setLoading] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [generalError, setGeneralError] = useState(null);
    const auth = getAuth();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const sharedProps = {
        register,
        errors,
        watch
    }

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            await api.post('/auth/clean-register', data);
            await signInWithEmailAndPassword(auth, data.email, data.password);

        } catch (err) {
            setGeneralError(err.message)
        }
        setLoading(false);
    }
    
    return <>
        <Header />
        <Wrapper className="flex flex-col pt-10">
            <div className="flex flex-1 px-10 md:px-0 md:w-[420px] flex-col self-center items-center">
                
                <div className="text-18 font-satoshi-medium mb-2 text-coolgray">Account information</div>
                <h1 className='text-32 font-satoshi-medium text-navy'>Let's create your account</h1>

                <Form className="mt-10 w-full">
                    <Input label="First Name" id='first_name' {...sharedProps}  />
                    <Input label="Last Name" id='last_name' {...sharedProps} />
                    <Input label="Email Address" type='email' formProps={{ enforcePattern: emailRegex, required: true }} id='email' {...sharedProps} />
                    <Input label="Password" type='password' id='password' {...sharedProps} />
                    <Input label="Confirm Password" type='password' id='confirmPassword' formProps={{
                        validate: value => value === watch('password') || 'Passwords do not match'
                    }} {...sharedProps} />
                    <Input label="Phone Number" type='phone' id='phone' {...sharedProps} />
                </Form>

                <AcceptTerms 
                setAccepted={setTermsAccepted} accepted={termsAccepted} 
                className={`
                    mt-[25px] border-gray-200 border-[1px] 
                    rounded-md py-4 px-6 mb-5`} />

                <ConfirmButton disabled={!termsAccepted || loading} loading={loading} onClick={handleSubmit(onSubmit)} />
                {generalError && <span className="text-red-500 text-sm">{generalError}</span>}

                <div className="h-[1px] bg-gray-200 w-full mt-5 mb-5" />
                <a className='underline text-coolgray mb-10' href='/sign-in'>Already have an account?</a>
            </div>

        </Wrapper>
    </>


}