import styled from "styled-components"
import { RegistrationForm } from "./template";
import { useSelector } from "react-redux";
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";
import { habitsLifestyle } from "../../constants/registration/habits-lifestyle";
import { clearRegistrationData } from "../../utils/registration";

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "coffee_consumption"
        "tobacco_consumption"
        "waking_up_condition"
        "trouble_sleeping"
        "alcohol_consumption"
        "sleep_hours"
        "physical_activity_days"
`;

const requiredFields = [
    "coffee_consumption",
    "tobacco_consumption",
    "waking_up_condition",
    "trouble_sleeping",
    "alcohol_consumption",
    "sleep_hours",
    "physical_activity_days",
]




export const RegistrationForm06 = () => {

    const values = useSelector((state) => state.registration);
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const submit = async () => {
        try {
            const data = clearRegistrationData(values);
            const link = !!currentUser ? '/kit/register' : '/auth/clean-register';
            const result = await api.post(link, data);
            if (!data.barcode) navigate('/dashboard');
            if (result.data?.age_results_ready) navigate('/registration/success?age_available=' + result.data?.age_results_ready)
            else if (result.data?.created_report) navigate('/registration/success?report_available=' + result.data?.created_report)
            else navigate('/registration/success')
        } catch (err) {
            throw new Error(err.message);
        }
    }

    return <RegistrationForm
        subtitle='Habits & Lifestyle'
        title='We’re almost at the finish line!'
        Fields={Fields}
        fields={Object.values(habitsLifestyle)}
        progress={95}
        buttonCta="Submit"
        nextRoute='/registration/success'
        requiredFields={requiredFields}
        submitAction={submit}
    />
    
}