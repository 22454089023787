import React from 'react';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import upIcon from '../images/progress-bad-up.svg';
import downIcon from '../images/progress-good-down.svg';
import imgShare from '../images/img-share.svg';
import { convertYearsToYearsAndMonths } from '../utils/date';

const ImageWrapper = styled(Column)`
    height: 500px;
    width: 500px;
    background: rgba(2, 17, 39, 1);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
`;

const BackgroundImg = styled.img`
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
`;

const ImageTitle = styled.div`
    color: white;
    font-size: 23px;
    font-family: 'Satoshi-Medium';
`;

const ImageAgeWrapper = styled(Row)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const ImageColumn = styled(Column)`
    font-family: 'Satoshi-Regular';
`;

const AgeText = styled.div`
    background: -webkit-linear-gradient(180deg, #FFFFFF 17.8%, #A7AFFF 86.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Years = styled(AgeText)`
    color: white;
    font-size: 150px;
    line-height: 130px;
    margin-right: 5px;
    font-family: 'Satoshi-Regular';
`;

const Months = styled(AgeText)`
    color: white;
    font-size: 59px;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 45px;
`;

const Comparison = styled.div`
    color: white;
    font-size: 35px;
    line-height: 37px;
`;

const ImageIcon = styled.img`
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
`;

const AgeImageWrapper = styled.div`
    transform: scale(0.7);
    transform-origin: top left;
`;

const Container = styled.div`
    height: 350px;
    width: 350px;
    overflow: hidden;
`;

const Contents = styled(Column)`
    align-items: center;
    z-index: 1000;
`;

export const AgeImage = React.forwardRef(({
    chronologicalAge,
    referenceAge,
    predictedAge
}, ref) => {

    const difference = convertYearsToYearsAndMonths(Math.abs(referenceAge - chronologicalAge));
    const [years, months] = difference
    const younger = referenceAge < chronologicalAge

    return (
        <Container>
        <AgeImageWrapper>
            <ImageWrapper>
                <Contents>
                    <ImageIcon src={younger ? downIcon : upIcon} alt='' />
                    <ImageTitle>My {predictedAge ? 'Predicted Age' : 'TruMe Age'} is</ImageTitle>
                    <ImageAgeWrapper style={younger ? { marginLeft: 40 } : {}}>
                        <Years>{years}</Years>
                        <ImageColumn >
                            <Months>.{months || '0'}</Months>
                            <Comparison>years</Comparison>
                            <Comparison>{younger ? 'younger': 'older'}</Comparison>
                        </ImageColumn>
                    </ImageAgeWrapper>
                    <ImageTitle>than my Chronological Age</ImageTitle>
                </Contents>
                <BackgroundImg src={imgShare} />
            </ImageWrapper>
        </AgeImageWrapper>
        </Container>
    );
})

