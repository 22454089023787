import React, { useState, useRef, useEffect } from 'react';
import { StatusProgressBar } from './status-progress-bar';
import styled from 'styled-components';
import registeredIcon from '../images/registered.svg';
import inLabIcon from '../images/in-lab.svg';
import flaggedIcon from '../images/flagged.svg';
import arrowUpIcon from '../images/arrow-up.svg';
import arrowDownIcon from '../images/arrow-down.svg';
import { Column, Row } from './defaults';

const BREAKPOINT = 762

const Wrapper = styled(Row)`
    background: rgba(10, 18, 42, 1);
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    position: absolute;
    z-index: ${props => props.expand ? 999 : 0};
    width: 100%;

    @media (max-width: ${BREAKPOINT}px) {
        position: relative;
        align-items: initial;
        gap: 10px;
        height: initial;
        padding: 20px 10px;
    }
`;

const IconWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #4F59F0;
    border-radius: 8px;
`;

const IconRoundedWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: rgba(79, 89, 240, 0.4);
    border-radius: 100%;
    cursor: pointer;

    @media (max-width: ${BREAKPOINT}px) {
        width: 60px;
    }
`;

const IconHeaderWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 8px;
    border: 1px solid rgba(79, 89, 240, 1);
    font-size: 24px;
    color: rgba(255, 255, 255, 1);
`;

const Icon = styled.img`
    width: 25px;
`;


const Title = styled.div`
    font-size: 18px;
    color: white;
    flex: 1;
`;

const KitWrapper = styled(Row)`
    border-bottom: ${props => props.last ? 'initial' : '1px solid rgba(255,255,255,.1)'};
    align-items: center;
    flex-direction: row;
    gap: 20px;
    padding: 20px 10px;

    @media (max-width: ${BREAKPOINT}px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`;

const StatusBarWrapper = styled(Row)`
    border-bottom: ${props => props.last ? 'initial' : '1px solid rgba(255,255,255,.1)'};
    align-items: center;
    flex-direction: row;
    gap: 20px;
    padding: 10px;

    @media (max-width: ${BREAKPOINT}px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
        padding: 0;
    }
`;


const TopTitle = styled.div`
    color: white;
    opacity: 0.7;
    font-size: 14px;

    @media (max-width: ${BREAKPOINT}px) {
        font-size: 12px;
    }
`;

const PendingKits = styled(Column)`
    gap: 10px;
    flex: 1;
`;

const TitleColumn = styled(Column)`
    margin-bottom: 10px;
    justify-self: center;

    @media (min-width: ${BREAKPOINT}px) { 
        flex: 1;
        margin-bottom: 0px;
    }

`;

const Button = styled(Row)`
    border-radius: 100px;
    background: white;
    height: 56px;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    justify-content: center;
    color: #4F59F0;
    &:hover {
        color: rgba(10, 18, 42, 1);
    }
`;

function useOutsideAlerter(ref) {
    const [outsideChange, setOutsideChange] = useState(false)

    useEffect(() => {
        function handleClickOutside(event) {
            setOutsideChange(false)
            if (ref.current && !ref.current.contains(event.target)) {
                setOutsideChange(true);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return outsideChange
}

const StatusBarHeader = ({ onExpand, expand, kitsNumber }) => {

    return <StatusBarWrapper last={!expand}>
        <IconHeaderWrapper>
            {kitsNumber}
        </IconHeaderWrapper>
        <TitleColumn onClick={onExpand} style={{ cursor: 'pointer' }}>
            <TopTitle>Kits in Progress</TopTitle>
            <Title>Click here to expand and see statuses</Title>
        </TitleColumn>
        <IconRoundedWrapper onClick={onExpand}>
            <Icon src={expand ? arrowUpIcon : arrowDownIcon} />
        </IconRoundedWrapper>
    </StatusBarWrapper>
}


export const PendingStatusBarHeader = () => {
    return <StatusBarWrapper last={true}>
        <IconHeaderWrapper>
            1
        </IconHeaderWrapper>
        <TitleColumn>
            <TopTitle>Your Kits</TopTitle>
            <Title>We are processing your order. You will be receiving your test soon!</Title>
        </TitleColumn>
    </StatusBarWrapper>
}


const KitStatus = ({ status, barcode, test_types, last }) => {
                
    const testType = test_types[0]?.name;

    const testName = testType === 'Folate' ? 'DNA Methylation Genetic' : 'TruMe Age'
    
    const statuses = {
        'PENDING': {
            step: 0,
            title: '',
            showBar: true
        },
        'UNSASSIGNED': {
            step: 0,
            title: '',
            showBar: true
        },
        'REGISTERED': {
            step: 3,
            title: "Registered – You’ve successfully registered your kit.",
            showBar: true,
            icon: registeredIcon
        },
        'IN_LAB': {
            step: 4,
            title: 'Received in Lab – Your kit is now in the lab for testing.',
            showBar: true,
            icon: inLabIcon
        },
        'FLAGGED': {
            step: null,
            title: 'There’s an issue with your kit – Please contact support.',
            showBar: false,
            buttonText: 'Contact Support',
            icon: flaggedIcon,
            iconBackground: '#F9BF29',
            buttonLink: 'https://trumelabs.com/contact-us/'
        }
    }

    const step = statuses[status] !== undefined ? statuses[status].step : 4
    const title = statuses[status] !== undefined ? statuses[status].title : ''
    const showBar = statuses[status] !== undefined ? statuses[status].showBar : false
    const buttonText = statuses[status] !== undefined ? statuses[status].buttonText : ''
    const icon = statuses[status] !== undefined ? statuses[status].icon : undefined
    const iconBackground = statuses[status] !== undefined ? statuses[status].iconBackground : undefined
    const buttonLink = statuses[status] !== undefined ? statuses[status].buttonLink : undefined
    

    return <KitWrapper last={last}>
        <IconWrapper style={{ background: iconBackground || '#4F59F0' }}>
            <Icon src={icon} />
        </IconWrapper>
        <TitleColumn>
            <TopTitle>{testName} Test: {barcode}</TopTitle>
            <Title>{title}</Title>
        </TitleColumn>

        {
            showBar ?
            <StatusProgressBar warning={status === 'FLAGGED'} step={step} total={5} /> :
            <Button 
             style={{zIndex: 35}}
             onClick={() => window.open(buttonLink,'_blank')}
            >
                {buttonText}
            </Button>
        }
        

    </KitWrapper>
}

export const StatusBar = ({ pendingKits }) => {
    const [expand, setExpand] = useState(false)
    const wrapperRef = useRef(null);
    const outsideDetected = useOutsideAlerter(wrapperRef);

    useEffect(() => {
        if (outsideDetected && expand) {
            setExpand(false)
        }
    }, [outsideDetected, expand])

    return <Wrapper expand={expand}>
        <PendingKits ref={wrapperRef}>
            <StatusBarHeader onExpand={() => setExpand(previous => !previous)} expand={expand} kitsNumber={pendingKits.length} />
            {expand && pendingKits.map((kit, i) => <KitStatus last={i === pendingKits.length - 1} key={kit.id} {...kit} />)}
        </PendingKits>
    </Wrapper>

}


export const NoKitsStatusBar = () => {
    return <Wrapper>
        <PendingStatusBarHeader />
    </Wrapper>

}