/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components"
import moment from 'moment'
import arrowRight from '../images/arrow-right.svg'
import { ArrowLeftIcon } from '../images/arrow-left-icon.js'
import FieldDate from './onboarding-form/field-date.js'
import FieldInputsWithDropbox from './onboarding-form/field-input-with-dropbox.js'
import FieldSelector from './onboarding-form/field-selector.js'
import { ProgressBar } from './progress-bar';
import AgePredictNavbar from "./predict-bio-age/predict-bio-age-navbar.js"
import useAuth from '../utils/useAuth'
import { fetchUserData } from '../redux/reducer/user'
import { useFirebaseWarningRemove } from '../utils/firebase.js';
import { useVisualViewportDimensions } from '../utils/useDimensions.js';
import { getDateFormat, MOMENT_FORMAT } from '../utils/date.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShareIcon } from '../images/share-icon.js';


const OnboardingFormWrapper = styled.div`
  height: calc(100dvh - 90px);
  overflow: auto;
  padding: 0px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  justify-content: start;

   @media (max-width: 768px) {
      padding: 0px 10px 40px 10px;
      flex: none;
   }
`

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  gap: 40px;
  height: calc(100vh - 300px);

  @media (max-width: 768px) {
    justify-content: space-between;
    gap: 0px;
    height: 100%;
  }
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin: auto 0;
  }
`

const FieldDescription = styled.span`
  color: #5B637D;
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  letter-spacing: 0.306px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const SubmitButton = styled.button`
  width: fit-content;
  background-color: #4F59F0;
  border-radius: 48px;
  padding: 13px 16px;
  border: none;
  color: #fff;
  display: flex;
  gap: 6px;
  align-items: center;
  font-family: 'Satoshi-Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  opacity: ${props => props.disabled ? '0.8' : '1'};
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  justify-self: center;

  @media (max-width: 768px) {
    align-self: center;
    display: flex;
    width: 100%;
    justify-content: center;
    transition: 'transform 0.3s ease, top 0.3s ease';
  }
`

const SubmitButtonFloat = styled(SubmitButton)`
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 20px;
  top: ${props => props.viewportHeight + 50}px;
  z-index: 999999;
`

const Icon = styled.img``

const FieldDescriptionWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const QuestionButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    border-radius: 100%;
    border: 1px solid #D9D9D9;
    width: 40px;
    height: 40px;
  }
`

const FieldHeaderMobileWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
  }
`

const FieldDescriptionLeft = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`

const FieldQuestion = styled.span`
  color: #051F73;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const FieldQuestionWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.autoSubmit ? 'column' : 'row'};
  gap: ${props => props.autoSubmit ? '30px' : '3px'};
  align-items: center;
`

const ProgressBarWrapper = styled.div`
  background: blue;
`

const fieldTypes = {
  date: FieldDate,
  dropbox: FieldInputsWithDropbox,
  selector: FieldSelector
}

const Field = ({
  field,
  fieldAnswers,
  onUpdate,
  onPreviousQuestion,
  hasPreviousQuestion,
  submitAction,
  myDivRef
}) => {
  const FieldType = fieldTypes[field.type]
  return <FieldWrapper autoSubmit={field.autoSubmit} ref={myDivRef}>
    <FieldDescriptionWrapper>
      <FieldDescriptionLeft>
        {hasPreviousQuestion() && (
          <QuestionButton onClick={onPreviousQuestion}>
            <ArrowLeftIcon fill="#5B637D" />
          </QuestionButton>
        )}
      </FieldDescriptionLeft>
      <FieldDescription>{field.description}</FieldDescription>
    </FieldDescriptionWrapper>
    <FieldQuestionWrapper autoSubmit={field.autoSubmit}>
      {
        !field.renderInternalQuestion && <FieldQuestion>
          {field.question}
        </FieldQuestion>
      }
      <FieldType
        field={field}
        fieldAnswers={fieldAnswers}
        onUpdate={onUpdate}
        submitAction={submitAction}
      />
    </FieldQuestionWrapper>
  </FieldWrapper>
}


const FieldHeaderMobile = ({
  setShareBannerVisible,
  hasPreviousQuestion,
  onPreviousQuestion
}) => {

  return <FieldHeaderMobileWrapper>
      {hasPreviousQuestion() && (
        <QuestionButton onClick={onPreviousQuestion}>
          <ArrowLeftIcon fill="#5B637D" />
        </QuestionButton>
      )}
      {setShareBannerVisible && <QuestionButton onClick={() => setShareBannerVisible(true)}>
        <ShareIcon fill="#02145E" />
      </QuestionButton>}
  </FieldHeaderMobileWrapper>


}

export const OnboardingForm = ({ 
  fields, 
  footer = <div />, 
  setShareBannerVisible,
  onEndForm
}) => {
  
  const hash = useLocation().hash;
  const navigate = useNavigate();
  const step = hash ? parseInt(hash.replace('#', '')) : 0;
  const setStep = (step) => navigate(`#${step}`);
  const field = fields[step];

  const [isValid, setIsValid] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fieldAnswers, setFieldAnswers] = useState({})
  const [submitButtonText, setSubmitButtonText] = useState('Continue')
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [viewportHeight, setViewportHeight] = useState(0);

  const myDivRef = useRef(null);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  const handleResize = () => {
    const viewportHeight = window.visualViewport.height;
    const keyboardOpen = viewportHeight < window.innerHeight;
    setViewportHeight(viewportHeight);
    setIsKeyboardVisible(keyboardOpen);
  };

  useFirebaseWarningRemove();
  useVisualViewportDimensions(handleResize);

  useEffect(() => {
    if (!isKeyboardVisible) {
      window.scrollTo(0, 0);
    }
  }, [isKeyboardVisible])
  
  useEffect(() => {
      if (currentUser) {
          dispatch(fetchUserData())
      };
  }, [currentUser])

  useEffect(() => {
    if (sessionStorage.getItem('onboardingFormAnswers')) {
      const answers = sessionStorage.getItem('onboardingFormAnswers');
      setFieldAnswers(JSON.parse(answers));
    };
  }, [])

  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (userData?.first_name) {
      autoFillAnswers(userData)
    }
  }, [userData])

  const autoFillAnswers  = (userData) => {
    if (userData?.date_of_birth) {
      setFieldAnswers(previous => ({
        ...previous,
        date_of_birth: moment(userData.date_of_birth).format(getDateFormat(MOMENT_FORMAT))
      }))
      if (field.name === 'date_of_birth') {
        onNext()
      }
    }
  }

  const onUpdate = (isValid, fieldUpdate) => {
    let newAnswers;
    setFieldAnswers(previous => {
      newAnswers = {
        ...previous,
        ...fieldUpdate
      }
      return newAnswers
    })
    setIsValid(isValid)
    if (isValid && fieldUpdate && field.autoSubmit) {
      setTimeout(() => onNext(newAnswers), 500)
    }
  }

  const onNext = (answers) => {
    if (hasNextQuestion()) {
      nextQuestion()
      return
    } else {
      sessionStorage.setItem('onboardingFormAnswers', JSON.stringify(answers))
      onEndForm()
    }
  }
  
  const nextQuestion = () => {
    setStep(step + 1)
  }

  const previousQuestion = () => {
    navigate(-1);
  }

  const calculateProgress = () => {
    return (step / (fields.length - 1)) * 100
  }

  useEffect(() => {
    setProgress(calculateProgress())
    if (step < fields.length - 1) {
      setSubmitButtonText('Continue')
      return
    }
    setSubmitButtonText('Submit')
  }, [step])

  const onPreviousQuestion = () => {
    previousQuestion()
  }

  const hasPreviousQuestion = () => step > 0

  const hasNextQuestion = () => step < fields.length - 1

  return (
    <>
      <AgePredictNavbar />
      <ProgressBarWrapper>
        <ProgressBar percent={progress} width='100%' background="linear-gradient(271deg, #4F59F0 -3.59%, #B5EDFF 92.5%)" />
      </ProgressBarWrapper>
      <OnboardingFormWrapper>
          <FieldHeaderMobile 
            onPreviousQuestion={onPreviousQuestion}
            hasPreviousQuestion={hasPreviousQuestion}
            setShareBannerVisible={setShareBannerVisible}
          />
          <QuestionWrapper>
            <Field 
              key={field.question}
              field={field}
              fieldAnswers={fieldAnswers}
              onUpdate={onUpdate}
              onPreviousQuestion={onPreviousQuestion}
              hasPreviousQuestion={hasPreviousQuestion}
              hasNextQuestion={hasNextQuestion}
              myDivRef={myDivRef}
              submitAction={onNext}
            />
            {!field.autoSubmit ? (
              <>
                {
                  !isKeyboardVisible ? 
                  <SubmitButton
                    disabled={!isValid}
                    onClick={onNext}
                    isKeyboardVisible={isKeyboardVisible}
                  >
                    {submitButtonText}
                    <Icon src={arrowRight} />
                  </SubmitButton>
                  :
                <SubmitButtonFloat viewportHeight={viewportHeight} disabled={!isValid} onClick={onNext}>
                  <Icon src={arrowRight} />
                </SubmitButtonFloat>
                }
              </>
            ) : <div></div>}
          </QuestionWrapper>
      {footer}
      </OnboardingFormWrapper>
    </>
  )
}