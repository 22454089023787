import arrowRight from '../images/arrow-white.svg';
import styled from 'styled-components';

const SecondaryButtonWrapper = styled.button`
    padding: 16px 20px;
    border-radius: 100px;
    background: #4F59F0;
    color: white;
    font-family: 'Satoshi-Bold';
    align-items: center;
    justify-content: center;
    display: flex;
    text-wrap: nowrap;
    cursor: pointer;
    border: none;
    font-size: 16px;

    img {
        height: 15px;
        width: 15px;
        margin-left: 15px;
    }
`;

export const SecondaryButton = ({ children, ...props}) => {

    return <SecondaryButtonWrapper {...props}>
        {children}
        <img src={arrowRight} alt='' />
    </SecondaryButtonWrapper>

}