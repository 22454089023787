import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import shareIcon from '../images/share-blue.svg';
import loadingIcon from '../images/3-dots-move-purple.svg';
import { toBlob } from 'html-to-image';
import { Modal } from './modal';
import { isMobileBrowser } from '../utils/navigator';

const BannerModal = styled(Modal)`
    position: fixed;
    z-index: 10000; 
    min-height: 100vh !important;
    overflow-y: scroll;
    background: rgba(0,0,0,.2);
    backdrop-filter: blur(5px);
`;

const Box = styled(Column)`
    border-radius: 25px;
    padding: 24px;
    justify-self: center;
    margin-top: 150px;
    margin-bottom: 100px;
    background: white;
    position: relative;

    @media (max-width: 760px) {
        padding: 10px;
        border-radius: 15px;
        gap: 10px;
        justify-content: center;
    }
`;

const ButtonRow = styled(Row)`
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

const SaveButton = styled(Row)`
    cursor: pointer;
    height: 50px;
    box-sizing: border-box;
    border-radius: 100px;
    font-size: 20px;
    align-items: center;
    padding: 12px 24px;
    border: 1px solid rgba(5, 31, 115, 1);
    color: rgba(5, 31, 115, 1);
    gap: 10px;
    font-family: 'Satoshi-Medium';
    align-self: center;
    justify-self: center;

    ${props => props.disabled && `pointer-events: none;`};

    @media (max-width: 760px) {
        font-size: 16px;
        padding: 10px 20px;
        height: 40px;
        gap: 5px;
    }
`;

const ShareIcon = styled.img`
    height: 20px;
    width: 20px;

    @media (max-width: 760px) {
        height: 15px;
    }
`;

const ImageWrapper = styled.div`
    margin-bottom: 20px;
`;

async function shareImage(node, { title, text, url }, style, size = 350) {
    try {
        const args = {
            style,
            height: size,
            width: size
        };
        let blob;

        for (let b = 0; b < 15; b++) {
            blob = await toBlob(node, args);
            if (blob.size > 30000) break;
        }

        const files = [
            new File([blob], 'biological_age.png', { type: 'image/png' })
        ];

        const textData = [text, url].filter((v) => !!v).join('\n\n');

        const data = { files, text: textData }

        await navigator.share({
            title,
            ...data
        })
    } catch (error) {
        console.error('Error sharing image:', error);
    }
}

const RefImage = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: -1;
`;

export const ShareBanner = ({ 
    visible = true, 
    title,
    text = 'Answer a few questions and see how your lifestyle and health stack up against our data-driven insights.',
    image = <div />, 
    id='share-image',
    url = 'https://trumelabs.com',
    setVisible }) => {
        
    const [loading, setLoading] = useState(false);

    if (!visible) return null;
    
    const onClick = async () => {
        setLoading(true);
        await shareImage(
            document.getElementById(id),
            {
                title,
                text,
                url,
            }, 
            {},
            350
        )
        setLoading(false);
    }

    return ReactDOM.createPortal(<>
        <BannerModal onClickOutside={() => setVisible(false)}>
            <Box onClick={e => e.stopPropagation()}>
                <ImageWrapper>
                    {image}
                </ImageWrapper>
                <RefImage id='share-image'>
                    {image}
                </RefImage>
                <ButtonRow>
                    <SaveButton disabled={loading} onClick={onClick}> 
                        Share
                        <ShareIcon src={loading ? 
                        loadingIcon :
                        shareIcon} alt='' />
                    </SaveButton>

                    <SaveButton disabled={loading} onClick={() => setVisible(false)}> 
                        Cancel
                    </SaveButton>
                </ButtonRow>
            </Box>
        </BannerModal>
    </>, document.getElementById('modal-portal'))

}