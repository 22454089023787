import styled from "styled-components";

const AcceptTermsWrapper = styled.label`
    display: flex;
    gap: 15px;

    span {
        font-family: 'Satoshi-Bold';
        font-size: 15px;
        color: #5B637D;
    }

    a {
        font-family: 'Satoshi-Bold';
        cursor: pointer;
        color: #4F59F0;
    }
`;

export const AcceptTerms = ({
    setAccepted,
    accepted,
    className
}) => {


    return <AcceptTermsWrapper className={`${className}`}>
        <input value={accepted} onChange={(e) => setAccepted(!accepted)} type="checkbox" />
        <span>By creating an account you agree to the <a href='https://app.termly.io/policy-viewer/policy.html?policyUUID=e8d6c9f9-9472-4041-80f7-50ea5844a371'>Privacy Policy</a> and <a href='https://trumelabs.com/terms-conditions/'>Terms of Service</a></span>
    </AcceptTermsWrapper>

}