import styled from 'styled-components'
import backgroundFoot from '../../images/predict-bio-age-footer.svg';
import { ShareIcon } from '../../images/share-icon.js'


const PredictBioAgeFooterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-image: url(${backgroundFoot});
  background-size: cover;
  border-radius: 16px;
  margin: auto 33px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    display: none;
  }
`

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px 40px;
  justify-content: center;
  gap: 10px;
`

const Title = styled.span`
  color: #FFF;
  font-family: 'Satoshi-Medium';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 166.667% */
  letter-spacing: -0.312px;
`

const TitleHightlight = styled.span`
  color: #F9BF29;
`

const Description = styled.span`
  color: rgba(255, 255, 255, 0.83);
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 28.5px */
  letter-spacing: -0.247px;
`

const FooterRight = styled.div`
  display: flex;
  width: 50%;
  padding: 40px;
  justify-content: end;
  align-items: center;
`

const ShareButton = styled.button`
  display: flex;
  padding: 16px 16px 16px 40px;
  align-items: center;
  gap: 24px;
  height: 88px;
  border-radius: 106px;
  border: 1px solid rgba(255, 255, 255, 0.30);
  background: rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(20px);
  color: var(--White, #FFF);
  text-align: center;
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.247px;
  cursor: pointer;
`

const ShareIconWrapper = styled.div `
  background: #fff;
  display: flex;
  padding: 10px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
`

const PredictBioAgeFooter = ({ openShareModal }) => {
  return (
    <PredictBioAgeFooterWrapper>
      <FooterLeft>
        <Title>Health is better <TitleHightlight>together</TitleHightlight></Title>
        <Description>
          Health is the ultimate gift. Share this test with your loved ones and help them uncover their biological age—because awareness is the first step toward a healthier, longer life.
        </Description>
      </FooterLeft>
      <FooterRight>
        <ShareButton onClick={openShareModal}>
          Share With Loved Ones
          <ShareIconWrapper>
            <ShareIcon fill="#1C1B1F" width='24' height='24' />
          </ShareIconWrapper>
        </ShareButton>
      </FooterRight>
    </PredictBioAgeFooterWrapper>
  )
}

export default PredictBioAgeFooter